/* Homepages - Index Medical */
.slider-container-medical {
  background-color: #111;
}

@media (min-width: 1200px) {
  .slider-container-medical {
    height: 600px;
  }
}

.slider-container-medical .tp-caption strong {
  font-weight: 700 !important;
}

@media (min-width: 992px) {
  .services-group {
    margin-top: -100px;
  }
}

@media (max-width: 991px) {
  .services-group {
    margin-bottom: 40px;
  }
}

.quick-form-container .form-control {
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  .quick-form-container .btn {
    margin-top: 26px;
  }
}

.quick-gallery-showcase .portfolio-item {
  margin: 0;
  width: 50%;
}

@media (min-width: 481px) {
  .quick-gallery-showcase .portfolio-item {
    width: 33.3%;
  }
}

@media (min-width: 768px) {
  .quick-gallery-showcase .portfolio-item {
    width: 33.3%;
  }
}

.departments-section .department-action {
  max-width: 540px;
}

.text-block.hover-bg {
  border: 2px solid #fff;
  padding-top: 21px;
  padding-bottom: 17px;
  margin-bottom: 20px;
}

.text-block.hover-bg img {
  background-color: #0069b0;
  outline: 5px solid #0069b0;
}

.text-block.hover-bg:after {
  visibility: visible;
  opacity: 0.5;
}

.text-block.hover-bg:hover:after, .text-block.hover-bg:focus:after {
  transition: all 0.4s;
  visibility: hidden;
  opacity: 0;
}

.text-block.hover-bg.light .block-title,
.text-block.hover-bg:hover .block-title,
.text-block.hover-bg:focus .block-title {
  visibility: hidden;
  opacity: 0;
}

.text-block .block-title {
  transition: all 0.4s;
  color: #fff;
  visibility: visible;
}

.widget.hours-widget ul li {
  padding-bottom: 4px;
  margin-bottom: 4px;
  border-bottom: 1px dotted rgba(255, 255, 255, 0.2);
}

.widget.hours-widget ul li em {
  float: right;
}

.widget.hours-widget ul li:after {
  content: '';
  display: table;
  clear: both;
}

.services-group .service-row {
  display: flex;
}

@media (min-width: 768px) {
  .services-group .service {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .services-group .service {
    width: 33.33%;
  }
}

.departments-section .img-container {
  min-height: 220px;
}

.content-block > .row {
  display: flex;
  align-items: flex-end;
}

.tp-caption.NotGeneric-Title, .NotGeneric-Title {
  font-family: 'Open Sans', Arial, sans-serif, Arial, sans-serif;
  font-weight: 600;
}

.tp-caption.NotGeneric-SubTitle, .NotGeneric-SubTitle {
  font-family: 'Open Sans', Arial, sans-serif, Arial, sans-serif;
  font-weight: 600;
}
