/* Homepages - Index Medical */
@import '../mixins';
@import '../variables';

.slider-container-medical {
	background-color: #111;

	@media (min-width: $minmqlg) {
		height: 600px;
	}

	.tp-caption {
		strong {
			font-weight: 700 !important;
		}
	}
}

.services-group {
	@media (min-width: $minmqmd) {
		margin-top:-100px;
	}

	@media (max-width: $maxmqmd) {
		margin-bottom:40px;
	}
}

.quick-form-container {
	.form-control {
		margin-bottom: 10px;
	}

	.btn {
		@media (min-width: $minmqmd) {
			margin-top: 26px;
		}
	}

}

.quick-gallery-showcase  {
	.portfolio-item {
		margin:0;
		width:50%;

		@media (min-width: $minmqxs) {
			width: 33.3%;
		}

		@media (min-width: $minmqsm) {
			//width: 25%;
			width: 33.3%; // [my]
		}
	}
}

.departments-section {
	.department-action {
		max-width: 540px;
	}
}


//.text-block.hover-bg { [my]
//	border:2px solid #fff;
//	padding-top:21px;
//	padding-bottom:17px;
//	margin-bottom:20px;
//
//	img {
//		background-color: $first-color;
//		outline: 5px solid $first-color;
//	}
//}


.text-block.hover-bg {
	border:2px solid #fff;
	padding-top:21px;
	padding-bottom:17px;
	margin-bottom:20px;

	img {
		background-color: $first-color;
		outline: 5px solid $first-color;
	}
}

.text-block.hover-bg:after {
	visibility: visible;
	opacity: 0.5;
}

.text-block.hover-bg:hover:after, .text-block.hover-bg:focus:after{
	transition: all 0.4s;
	visibility: hidden;
	opacity: 0;
}

.text-block.hover-bg.light .block-title,
.text-block.hover-bg:hover .block-title,
.text-block.hover-bg:focus .block-title {
	visibility: hidden;
	opacity: 0;
}

.text-block .block-title {
	transition: all 0.4s;
	color: #fff;
	visibility: visible;
}


.widget.hours-widget {
	ul {
		li {
			padding-bottom:4px;
			margin-bottom:4px;
			border-bottom:1px dotted rgba(255, 255, 255, 0.2);
			em {
				float:right;
			}

			&:after {
				content: '';
				display: table;
				clear:both;
			}
		}

	}
}

.services-group {

	.service-row{display: flex;}

	.service {

		@media (min-width: $minmqsm) {
			width: 50%;
			//float: left;
			//padding-left: 25px;
			//padding-right: 25px;
		}

		@media (min-width: $minmqmd) {
			width: 33.33%;
		}
	}
}


.departments-section{
	.img-container{
		//min-height: 277px;
		min-height: 220px; // [my]
		// TODO add media
	}
}

.content-block > .row{
	display: flex;
	align-items: flex-end;
}

// [my]
.tp-caption.NotGeneric-Title,.NotGeneric-Title{
	font-family: 'Open Sans', Arial, sans-serif, Arial, sans-serif;
	font-weight: 600;
}

.tp-caption.NotGeneric-SubTitle,.NotGeneric-SubTitle{
	font-family: 'Open Sans', Arial, sans-serif, Arial, sans-serif;
	font-weight: 600;
}
